const LoginHeader = () => {
    return (
        <>
        <header>
            <nav>
                <div id="login-head">
                    <p>Welcome to VEO Veneht Bloggers!</p>
                </div>
            </nav>
        </header>
        </>
    )
}

export default LoginHeader;